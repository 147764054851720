// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
const SvgStatusBubbleOnLight = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<circle
			cx={9}
			cy={9}
			r={9}
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
export default SvgStatusBubbleOnLight;
