"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_query_1 = require("@tanstack/react-query");
const react_router_dom_1 = require("react-router-dom");
const smallLogo = require('../../../../../../assets/img/hsbc/smallLogo.png');
const userQuery_1 = require("../../../../queries/userQuery");
const avatarUtils_1 = require("../../../../utils/avatarUtils");
const _1 = require(".");
const StyledAvatar = (0, material_1.styled)(material_1.Avatar)(ownerState => {
    const { 'ts-variant': tsVariant, theme } = ownerState;
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        width: `${_1.DEFAULT_IMG_SIZE}px`,
        height: `${_1.DEFAULT_IMG_SIZE}px`,
        position: 'absolute',
        borderRadius: '100%',
        textAlign: 'center',
        ...(tsVariant === 'isUserAuthor'
            ? globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                ? {
                    top: '-35px',
                    right: 0,
                }
                : { top: 0, right: 0, transform: 'translate(50%, -50%)' }
            : globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                ? {
                    top: '-35px',
                    left: 0,
                }
                : { top: 0, left: 0, transform: 'translate(50%, -50%)' }),
    };
});
const UserAvatar = ({ isCurrentUserAuthor, event, style }) => {
    const initialData = (0, react_router_dom_1.useLoaderData)();
    const { data: currentUser } = (0, react_query_1.useQuery)({
        ...userQuery_1.userQuery,
        initialData: initialData.user,
    });
    const userAvatar = (0, avatarUtils_1.getSquare)(isCurrentUserAuthor ? currentUser?.avatarUrl : event.author.avatarUrl, _1.DEFAULT_IMG_SIZE);
    return userAvatar ? (globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? ((0, jsx_runtime_1.jsx)(StyledAvatar, { style: style || {}, "ts-variant": isCurrentUserAuthor ? 'isUserAuthor' : undefined, src: smallLogo.default })) : ((0, jsx_runtime_1.jsx)(StyledAvatar, { style: style || {}, "ts-variant": isCurrentUserAuthor ? 'isUserAuthor' : undefined, src: userAvatar }))) : globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? ((0, jsx_runtime_1.jsx)(StyledAvatar, { style: style || {}, "ts-variant": isCurrentUserAuthor ? 'isUserAuthor' : undefined, src: smallLogo.default })) : ((0, jsx_runtime_1.jsx)(StyledAvatar, { style: style || {}, ...(0, avatarUtils_1.stringAvatar)(isCurrentUserAuthor ? currentUser.fullName : event.author.fullName || 'N/A'), "ts-variant": isCurrentUserAuthor ? 'isUserAuthor' : undefined }));
};
exports.default = UserAvatar;
