// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
const SvgStatusNoOnLight = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M-1-1h20v20H-1z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="m17.25 1.69-.94-.94L9 8.06 1.69.75l-.94.94L8.06 9 .75 16.31l.94.94L9 9.94l7.31 7.31.94-.94L9.94 9z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
export default SvgStatusNoOnLight;
