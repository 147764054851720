// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
const SvgStatusWarningOnDark = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M9 0a9 9 0 1 0 9 9 9 9 0 0 0-9-9m-.9 3.75h1.8v6H8.1Zm.9 10.5A1.25 1.25 0 1 1 10.25 13 1.25 1.25 0 0 1 9 14.25"
			style={{
				fill: '#fff',
			}}
		/>
	</svg>
);
export default SvgStatusWarningOnDark;
