"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledFormLabel = void 0;
const material_1 = require("@mui/material");
exports.StyledFormLabel = (0, material_1.styled)(material_1.FormLabel)(({ theme }) => ({
    position: 'static',
    transform: 'none',
    fontWeight: theme.typography.tsFontWeight.bold,
    fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
    whiteSpace: 'nowrap',
    wordWrap: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: `${theme.palette.common.black} !important`,
    paddingBottom: theme.tsGoUnit.tsUnitHalf,
    ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? {
            fontWeight: theme.typography.tsFontWeight.default,
        }
        : {}),
}));
