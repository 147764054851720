"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Banner = void 0;
const material_1 = require("@mui/material");
const goLogin = require('../../../../../../../assets/img/artwork_go_login.svg');
const hsbcLogin = require('../../../../../../../assets/img/hsbc/HSBC_Transferring balances_between_cards.png');
exports.Banner = (0, material_1.styled)('div')(({ theme }) => {
    const backgroundImage = globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? hsbcLogin.default : goLogin.default;
    const backgroundColor = globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'hsla(0, 0%, 93%, 1)' : 'inherit';
    return {
        margin: 0,
        padding: 0,
        border: theme.palette.tsGoBorder.noBorder,
        boxSizing: 'border-box',
        backgroundSize: 'cover',
        width: '100%',
        height: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? '191px' : '28dvh',
        borderBottom: `thin solid ${theme.palette.tsGoColor.grayLight()}`,
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor,
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                backgroundSize: 'auto 300px',
                backgroundPosition: 'right center',
            }
            : {}),
    };
});
