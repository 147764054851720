import _ from 'lodash';

const errorCodes = require('../../../common/constants/errorCodes');

const CompanyDashboardComponent = {
	bindings: {
		approvalItems: '<',
		teams: '<',
		users: '<',
	},
	template: `
	<div class="company-dashboard">
			<div class="company-dashboard-row" ng-if="$ctrl.isMetricsDataUnavailable">
				<div data-ts="Note" class="data-unavailable-note">
					<i class="ts-icon-info"></i>
					<p ng-bind="$ctrl.unavailableDataText"></p>
				</div>
			</div>
			<div class="company-dashboard-row">
				<dashboard-box
						class="left-dashboard-box tst-overview-header"
						title="{{$ctrl.overviewTitleText}}">
					<dashboard-box-loader ng-if="$ctrl.loading">
					</dashboard-box-loader>
					<div class="overview-container" ng-if="!$ctrl.loading">
						<div class="flex-row">
							<overview-box
									amount="$ctrl.overview.activeCardCount"
									borders="['border-right', 'border-bottom']"
									text="{{$ctrl.activeCardsText}}"
									type="virtual-cards"></overview-box>
							<overview-box
									amount="$ctrl.overview.availableBalance"
									borders="['border-bottom']"
									currency="{{$ctrl.overview.currency}}"
									text="{{$ctrl.availableBalanceText}}"
									type="available-balance"></overview-box>
						</div>
						<div class="flex-row">
							<overview-box
									amount="$ctrl.overview.approvedAmount"
									borders="['border-right', 'border-top']"
									currency="{{$ctrl.overview.currency}}"
									text="{{$ctrl.approvedAmountText}}"
									type="approved-amount"></overview-box>
							<overview-box
									amount="$ctrl.overview.spentAmount"
									borders="['border-top']"
									currency="{{$ctrl.overview.currency}}"
									text="{{$ctrl.spentAmountText}}"
									type="spent-amount"></overview-box>
						</div>
					</div>
				</dashboard-box>
				<dashboard-box class="bar-chart" title="{{$ctrl.spendOverTimeText}}">
				</dashboard-box>
			</div>
			<div class="company-dashboard-row">
				<dashboard-box
						class="left-dashboard-box"
						title="{{$ctrl.teamsText}}">
					<dashboard-box-loader ng-if="$ctrl.loading">
					</dashboard-box-loader>
					<div class="flex-column" ng-if="!$ctrl.loading">
						<div class="flex-row team-box">
							<teams-box
									currency="{{$ctrl.overview.currency}}"
									teams="$ctrl.teams"
									dashboard-metrics="$ctrl.dashboardMetrics"></teams-box>
							<div
									class="empty-team-state"
									ng-if="$ctrl.teams.length === 0">
								<div class="empty-team-state-illustration"></div>
								<p ng-bind="$ctrl.noTeamsText"></p>
							</div>
						</div>
					</div>
				</dashboard-box>
				<dashboard-box
						class="activity-feed"
						ng-class="{ 'empty-team-state-activity': $ctrl.teams.length === 0 }"
						on-search="$ctrl.onActivityFeedSearch($event)"
						search-placeholder="Search by name or description"
						title="{{$ctrl.activityText}}">
					<activity-feed-box
							is-company-admin="true"
							search-query="$ctrl.activityFeedSearchQuery"
							download-enabled="true">
					</activity-feed-box>
				</dashboard-box>
			</div>
	</div>
	`,
	controller: /* @ngInject */ function (DashboardService, HeaderService, LexicoService, PaymentsService) {
		const ctrl = this;
		this.lexico = LexicoService.getLexico();
		this.overviewTitleText = this.lexico.trc('Component title', 'Overview');
		this.activeCardsText = this.lexico.trc('Component title', 'Active Cards');
		this.availableBalanceText = this.lexico.trc('Component title', 'Available Balance');
		this.approvedAmountText = this.lexico.trc('Component title', 'Approved Amount');
		this.spentAmountText = this.lexico.trc('Component title', 'Spent Amount');
		this.spendOverTimeText = this.lexico.trc('Component title', 'Spend Over Time');
		this.teamsText = this.lexico.trc('Component title', 'Teams');
		this.noTeamsText = this.lexico.tr('No teams to show');
		this.activityText = this.lexico.trc('Component title', 'Activity');
		this.unavailableDataText = this.lexico.tr(
			'Overview and Teams data is temporarily unavailable due to a high volume of active cards.',
		);
		this.dashboardMetrics = {
			overview: {
				currency: 'N/A',
			},
			teams: [],
		};
		this.overview = this.dashboardMetrics.overview;
		this.isMetricsDataUnavailable = false;
		this.loading = true;

		this.$onInit = () => {
			this.activityFeedSearchQuery = '';
			HeaderService.create({
				title: this.lexico.trc('Component title', 'Company Dashboard'),
				type: 'lite',
			});
			return Promise.all([DashboardService.getCompanyMetrics(), PaymentsService.getCurrencies()])
				.then(([dashboardMetrics, currencies]) => {
					ctrl.dashboardMetrics = dashboardMetrics;
					ctrl.overview = _.get(dashboardMetrics, 'overview', [])[0];
					ctrl.overview.currency = currencies?.[0] || 'N/A';
				})
				.catch(err => {
					ctrl.isMetricsDataUnavailable = err.code === errorCodes.VIRTUAL_CARDS_DASHBOARD_LIMIT_ERROR_CODE;
					if (!ctrl.isMetricsDataUnavailable) {
						const errorMessageLine1 = ctrl.lexico.tr("We're sorry, your Dashboard cannot be loaded.");
						const errorMessageLine2 = ctrl.lexico.tr('In some cases simply trying again helps.');
						ts.ui.Notification.warning(`${errorMessageLine1}\n\n${errorMessageLine2}`);
					}
				})
				.finally(() => {
					ctrl.loading = false;
				});
		};

		this.onActivityFeedSearch = ({ query }) => {
			this.activityFeedSearchQuery = query;
		};
	},
};

export default CompanyDashboardComponent;
