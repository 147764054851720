"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiFormLabelOverrides = void 0;
const material_1 = require("@mui/material");
exports.muiFormLabelOverrides = {
    styleOverrides: {
        root: () => {
            return {
                [`.${material_1.formLabelClasses.asterisk}`]: { opacity: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 0 : 1 },
            };
        },
    },
};
