import _ from 'lodash';

const errorCodes = require('../../../common/constants/errorCodes');

const ApproverDashboardComponent = {
	bindings: {
		isCompanyAdmin: '<',
		shouldReturnToCompanyDashboard: '<',
		showDownloadReportButton: '<',
		teamId: '<',
		team: '<',
		user: '<',
		userRole: '<',
	},
	template: `
	<div class="team-dashboard">
		<div class="team-dashboard-row" ng-if="$ctrl.isMetricsDataUnavailable">
			<div data-ts="Note" class="data-unavailable-note">
				<i class="ts-icon-info"></i>
				<p ng-bind="$ctrl.unavailableDataText"></p>
			</div>
		</div>

		<div class="team-dashboard-row">
			<dashboard-box
					class="left-dashboard-box tst-overview-header"
					title="{{$ctrl.overviewTitleText}}">
				<dashboard-box-loader ng-if="$ctrl.loading">
				</dashboard-box-loader>
				<div class="overview-container" ng-if="!$ctrl.loading">
					<div class="flex-row overview">
						<overview-box
								amount="$ctrl.overview.activeCardCount"
								borders="['border-right', 'border-bottom']"
								text="{{$ctrl.activeCardsText}}"
								type="virtual-cards"></overview-box>
						<overview-box
								amount="$ctrl.overview.availableBalance"
								borders="['border-bottom']"
								currency="{{$ctrl.overview.currency}}"
								text="{{$ctrl.availableBalanceText}}"
								type="available-balance"></overview-box>
					</div>
					<div class="flex-row overview">
						<overview-box
								amount="$ctrl.overview.approvedAmount"
								borders="['border-right', 'border-top']"
								currency="{{$ctrl.overview.currency}}"
								text="{{$ctrl.approvedAmountText}}"
								type="approved-amount"></overview-box>
						<overview-box
								amount="$ctrl.overview.spentAmount"
								borders="['border-top']"
								currency="{{$ctrl.overview.currency}}"
								text="{{$ctrl.spentAmountText}}"
								type="spent-amount"></overview-box>
					</div>
				</div>
			</dashboard-box>
			<dashboard-box class="bar-chart" title="{{$ctrl.spendOverTimeText}}">
			</dashboard-box>
		</div>
		<div class="team-dashboard-row">
				<dashboard-box
						class="team-member-box left-dashboard-box"
						title="{{$ctrl.teamMembersText}}">
					<dashboard-box-loader ng-if="$ctrl.loading">
					</dashboard-box-loader>
					<div
							class="empty-members-state"
							ng-if="!$ctrl.loading && $ctrl.dashboardMetrics.people.length === 0">
						<div class="empty-members-state-illustration"></div>
						<p ng-bind="$ctrl.emptyMembersText"></p>
					</div>
					<team-member-box
							ng-if="!$ctrl.loading"
							currency="{{$ctrl.overview.currency}}"
							dashboard-metrics="$ctrl.dashboardMetrics"></team-member-box>
				</dashboard-box>

				<dashboard-box
						class="activity-feed"
						ng-class="{ 'empty-members-state-activity': $ctrl.dashboardMetrics.people.length === 0 }"
						title="{{$ctrl.activityText}}"
						on-search="$ctrl.onActivityFeedSearch($event)"
						search-placeholder="Search by name or description">
					<activity-feed-box
							is-company-admin="false"
							download-enabled="$ctrl.showDownloadReportButton"
							search-query="$ctrl.activityFeedSearchQuery"
							team-id="$ctrl.team.id"
							user="$ctrl.user">
					</activity-feed-box>
				</dashboard-box>
		</div>
	</div>
	`,
	controller: /* @ngInject */ function (
		$state,
		DashboardService,
		HeaderService,
		LexicoService,
		TeamsService,
		PaymentsService,
	) {
		const ctrl = this;
		this.lexico = LexicoService.getLexico();
		this.overviewTitleText = this.lexico.trc('Component title', 'Overview');
		this.activeCardsText = this.lexico.trc('Component title', 'Active Virtual Cards');
		this.availableBalanceText = this.lexico.trc('Component title', 'Available Balance');
		this.approvedAmountText = this.lexico.trc('Component title', 'Approved Amount');
		this.spentAmountText = this.lexico.trc('Component title', 'Spent Amount');
		this.spendOverTimeText = this.lexico.trc('Component title', 'Spend Over Time');
		this.teamMembersText = this.lexico.trc('Component title', 'Team Members');
		this.emptyMembersText = this.lexico.trc('Empty list message', 'No members to show');
		this.activityText = this.lexico.trc('Component title', 'Activity');
		this.unavailableDataText = this.lexico.tr(
			'Overview and Team Members data is temporarily unavailable due to a high volume of active cards.',
		);
		this.dashboardMetrics = {
			overview: {
				currency: 'N/A',
			},
			people: [],
		};
		this.overview = this.dashboardMetrics.overview;
		this.isMetricsDataUnavailable = false;
		this.loading = true;

		const createHeader = team => {
			const spendDashboardTitle = this.lexico.trc('Component title', 'Spend Dashboard');
			const title = team ? `${spendDashboardTitle} - ${team.name}` : spendDashboardTitle;
			let backbutton;
			if (this.isCompanyAdmin) {
				backbutton = () => $state.go('main.companyDashboard');
			}
			const options = {
				backbutton,
				title,
				type: 'lite',
			};
			HeaderService.create(options);
		};

		this.onSwitchTeam = team => {
			createHeader(this.team);
			return $state.go('main.approverDashboard', { teamId: team.id });
		};

		this.loadMetrics = async () => {
			if (ctrl.userRole.id === 'manager') {
				return DashboardService.getManagerMetrics(ctrl.teamId);
			}
			return DashboardService.getTeamMetrics(ctrl.teamId);
		};

		this.$onInit = () => {
			this.activityFeedSearchQuery = '';
			// this method passes `team` into the callback arguments
			TeamsService.registerTeamSwitchObservable(this.onSwitchTeam);

			createHeader(this.team);

			return Promise.all([this.loadMetrics(), PaymentsService.getCurrencies()])
				.then(([dashboardMetrics, currencies]) => {
					ctrl.dashboardMetrics = dashboardMetrics;
					ctrl.overview = _.get(dashboardMetrics, 'overview', [])[0];
					ctrl.overview.currency = currencies?.[0] || 'N/A';
				})
				.catch(err => {
					ctrl.isMetricsDataUnavailable = err.code === errorCodes.VIRTUAL_CARDS_DASHBOARD_LIMIT_ERROR_CODE;
					if (!ctrl.isMetricsDataUnavailable) {
						const errorMessageLine1 = ctrl.lexico.tr("We're sorry, your Dashboard cannot be loaded.");
						const errorMessageLine2 = ctrl.lexico.tr('In some cases simply trying again helps.');
						ts.ui.Notification.warning(`${errorMessageLine1}\n\n${errorMessageLine2}`);
					}
				})
				.finally(() => {
					ctrl.loading = false;
				});
		};

		this.$onDestroy = () => {
			TeamsService.removeTeamSwitchObservable(this.onSwitchTeam);
		};

		this.onActivityFeedSearch = ({ query }) => {
			this.activityFeedSearchQuery = query;
		};
	},
};

export default ApproverDashboardComponent;
