"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const TagLabel = ({ text = '' } = {}) => {
    return ((0, jsx_runtime_1.jsx)(material_1.ListItem, { sx: [
            {
                pb: 0,
                ml: 1,
                display: 'list-item',
            },
            globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                ? {
                    fontWeight: 300,
                    fontSize: '12px',
                    color: '#000000',
                    textTransform: 'none',
                }
                : {},
        ], children: text }));
};
exports.default = TagLabel;
