"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiFormGroupOverrides = void 0;
exports.muiFormGroupOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                alignContent: 'flex-start',
                padding: `0 ${theme.tsGoUnit.tsUnitQuarter} ${theme.tsGoUnit.tsUnit} ${theme.tsGoUnit.tsUnitQuarter}`,
                ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                    ? {
                        padding: '0px 5px 20px 0px',
                    }
                    : {}),
                '& > :first-of-type': {
                    width: '100%',
                },
            };
        },
    },
};
