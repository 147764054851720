// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
const SvgStatusWarningOnLight = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<circle
			cx={9}
			cy={9}
			r={9}
			style={{
				fill: '#fb3',
			}}
		/>
		<path d="M9 11.8A1.2 1.2 0 1 1 7.8 13 1.2 1.2 0 0 1 9 11.8m-.9-2h1.8v-6H8.1Z" />
	</svg>
);
export default SvgStatusWarningOnLight;
