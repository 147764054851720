"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registrationInitialState = void 0;
exports.registrationSlice = registrationSlice;
const initialState = {
    firstName: '',
    lastName: '',
    companyName: '',
    isFormLoading: false,
    mfaData: null,
    showTwoFactorAuth: false,
    hasUserSkipMfa: false,
};
exports.registrationInitialState = {
    registration: initialState,
};
function registrationSlice(set) {
    return {
        ...exports.registrationInitialState,
        setRegistrationFirstName: (firstName) => {
            set((state) => {
                state.registration.firstName = firstName;
                return state;
            }, false, '[setRegistrationFirstName]');
        },
        setRegistrationLastName: (lastName) => {
            set((state) => {
                state.registration.lastName = lastName;
                return state;
            }, false, '[setRegistrationLastName]');
        },
        setRegistrationCompanyName: (companyName) => {
            set((state) => {
                state.registration.companyName = companyName;
                return state;
            }, false, '[setCompanyName]');
        },
        setRegistrationFormLoading: (isFormLoading) => {
            set((state) => {
                state.registration.isFormLoading = isFormLoading;
                return state;
            }, false, '[setRegistrationFormLoading]');
        },
        setMfaData(data) {
            set((state) => {
                state.registration.mfaData = { ...state.registration.mfaData, ...data };
                return state;
            }, false, '[setMfaData]');
        },
        setShowTwoFactorAuth: (showTwoFactorAuth) => {
            set((state) => {
                state.registration.showTwoFactorAuth = showTwoFactorAuth;
                return state;
            }, false, '[setShowTwoFactorAuth]');
        },
        setHasUserSkipMfa: (didUserSkipMfa) => {
            set((state) => {
                state.registration.hasUserSkipMfa = didUserSkipMfa;
                return state;
            }, false, '[setHasUserSkipMfa]');
        },
        resetRegistrationForm: () => {
            set((state) => {
                state.registration = initialState;
                return state;
            }, false, '[resetRegistrationForm]');
        },
    };
}
