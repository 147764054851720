"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const zustandStore_1 = require("../../../../zustandStore");
const Steps = () => {
    const theme = (0, material_1.useTheme)();
    const { steps } = (0, zustandStore_1.useZustand)(state => ({
        ...state,
    }));
    const allSteps = steps.collection.length === 4 ? 3 : steps.collection.length;
    const currentStep = steps.currentStep + 1 === 3 && allSteps === 4 ? 2 : steps.currentStep + 1;
    const progressPercentage = (currentStep / allSteps) * 100;
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { sx: {
            width: '100%',
            background: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            border: '1px solid #ccc',
            borderBottom: 'none',
            boxSizing: 'border-box',
            padding: '12px 13px',
        }, children: [(0, jsx_runtime_1.jsxs)(material_1.Typography, { sx: {
                    alignSelf: 'flex-start',
                    gap: '8px',
                    fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.medium),
                    fontWeight: 350,
                    color: 'hsla(0, 0%, 20%, 1)',
                    paddingBottom: '10px',
                }, children: ["Step ", currentStep, " of ", allSteps] }), (0, jsx_runtime_1.jsx)(material_1.LinearProgress, { sx: [
                    {
                        width: '100%',
                        height: '6px',
                        borderRadius: '4px',
                        mb: 1,
                        backgroundColor: 'hsla(0, 0%, 93%, 1)',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'hsla(355, 100%, 43%, 1)',
                        },
                    },
                    globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                        ? {
                            borderRadius: 0,
                        }
                        : {},
                ], variant: "determinate", value: progressPercentage })] }));
};
exports.default = Steps;
