"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columns = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const walletDetails_1 = require("../component/walletDetails");
exports.columns = [
    {
        id: 'date',
        ui() {
            return ((0, jsx_runtime_1.jsx)(material_1.Grid2, { container: true, sx: {
                    flex: 1,
                }, children: (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { children: "Date" }) }));
        },
    },
    {
        id: 'amount',
        ui() {
            return (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { sx: { textAlign: 'right' }, children: "Amount" });
        },
    },
    {
        id: 'details',
        ui() {
            return (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { children: "Details" });
        },
    },
    ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? []
        : [
            {
                id: 'Actions',
                ui() {
                    return ((0, jsx_runtime_1.jsx)(material_1.Grid2, { container: true, direction: "row", sx: {
                            flex: 0.5,
                            justifyContent: 'flex-start',
                            alignItems: 'flex-end',
                        }, children: (0, jsx_runtime_1.jsx)(walletDetails_1.TypographyFieldColumn, { variant: "h5", "ts-variant": 'tableHeaderTitle', children: "Actions" }) }));
                },
            },
        ]),
];
