// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
const SvgStatusYesOnLight = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M5.83 15.69.08 9.94l.84-.85 4.91 4.9L17.08 2.74l.84.85z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
export default SvgStatusYesOnLight;
