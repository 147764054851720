"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_query_1 = require("@tanstack/react-query");
const react_1 = require("react");
const appConstants_1 = require("../../../../constants/appConstants");
const strings_1 = require("../../../../constants/strings");
const FormLabel_1 = require("../../../../material-ui-theme/components/styled/FormLabel");
const codingQuery_1 = require("../../../../queries/codingQuery");
const zustandStore_1 = require("../../../../zustandStore");
const CodingHeaderSection = () => {
    const { data: codingLists = [] } = (0, react_query_1.useQuery)((0, codingQuery_1.getCodingListsQuery)({ skipDisabled: true }));
    const selectedCodingList = (0, zustandStore_1.useZustand)(state => state.codingList.currentCodingListSelected);
    const { setCurrentCodingListSelected } = (0, zustandStore_1.useZustand)();
    const CODING_LIST = appConstants_1.codingListConstants.codingList;
    (0, react_1.useEffect)(() => {
        if ((!selectedCodingList.id || !selectedCodingList.enabled) && codingLists?.length > 0 && codingLists[0]) {
            setCurrentCodingListSelected({
                name: codingLists[0].name,
                id: codingLists[0].ID,
                code: codingLists[0].code,
                enabled: codingLists[0].enabled,
            });
        }
    }, [selectedCodingList, codingLists]);
    return ((0, jsx_runtime_1.jsx)(material_1.Grid2, { container: true, spacing: 2, sx: {
            pb: 3,
        }, children: (0, jsx_runtime_1.jsx)(material_1.Grid2, { size: 4, children: (0, jsx_runtime_1.jsxs)(material_1.FormControl, { sx: { pt: 1, paddingLeft: 0 }, children: [(0, jsx_runtime_1.jsx)(FormLabel_1.StyledFormLabel, { children: strings_1.companySettingsCodingStrings.CODING_LIST }), (0, jsx_runtime_1.jsx)(material_1.Select, { name: CODING_LIST, value: selectedCodingList.id, onChange: event => {
                            const selectedList = codingLists?.find(item => item.ID === selectedCodingList.id);
                            if (!selectedList) {
                                return;
                            }
                            setCurrentCodingListSelected({
                                name: event.target.name,
                                id: event.target.value,
                                enabled: selectedList.enabled,
                            });
                        }, children: codingLists?.map(value => ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: value.ID, children: value.name }, value.ID))) })] }) }) }));
};
exports.default = CodingHeaderSection;
