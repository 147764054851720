"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dashboardInitialState = exports.timeSpanOptions = void 0;
exports.dashboardSlice = dashboardSlice;
exports.timeSpanOptions = [
    {
        value: 'monthly',
        label: 'Monthly',
    },
    {
        value: 'weekly',
        label: 'Weekly',
    },
    {
        value: 'daily',
        label: 'Daily',
    },
];
exports.dashboardInitialState = {
    dashboard: {
        settlements: [],
        timeSpan: 'monthly',
        chartData: [],
        dateRange: {
            fromDate: '',
            toDate: '',
        },
    },
};
function dashboardSlice(set) {
    return {
        ...exports.dashboardInitialState,
        setDashboardSettlements(settlements) {
            set((state) => {
                state.dashboard.settlements = settlements;
                return state;
            }, false, '[setDashboardSettlements]');
        },
        setTimeSpan(timeSpan) {
            set((state) => {
                state.dashboard.timeSpan = timeSpan;
                return state;
            }, false, '[setTimeSpan]');
        },
        setDateRange(dateRange) {
            set((state) => {
                state.dashboard.dateRange = dateRange;
                return state;
            }, false, '[setDateRange]');
        },
        setChartData(chartData) {
            set((state) => {
                state.dashboard.chartData = chartData;
                return state;
            }, false, '[setChartData]');
        },
    };
}
