"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledMuiTelInput = void 0;
const material_1 = require("@mui/material");
const mui_tel_input_1 = require("mui-tel-input");
exports.StyledMuiTelInput = (0, material_1.styled)(mui_tel_input_1.MuiTelInput)(() => ({
    [`& .${mui_tel_input_1.classes.flagButton}`]: {
        paddingLeft: '0',
        '& .MuiSvgIcon-root': {
            position: 'absolute',
            right: '-24px',
            pointerEvents: 'none',
        },
    },
    [`& .${mui_tel_input_1.classes.flagButton}:hover`]: {
        background: 'none',
    },
    ['& .MuiInputAdornment-root']: {
        marginRight: '5px',
    },
    [`& .${mui_tel_input_1.classes.flagContainer}::after`]: {
        content: '""',
        border: 'solid black',
        borderWidth: '0 1px 1px 0',
        display: 'inline-block',
        padding: '3px',
        transform: 'rotate(45deg)',
        marginLeft: '7px',
    },
    ['& .MuiTypography-body1']: {
        borderLeft: '1px solid #e0e0e0',
        paddingLeft: '10px',
        borderRight: 'none',
        paddingRight: '0',
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontSize: '0.875rem',
            }
            : {}),
    },
    ['& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart']: {
        paddingLeft: '0',
        fontSize: '0.875rem',
    },
}));
