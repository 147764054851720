"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRequestActivationWithTemporaryPasswordQuery = exports.useSendMfaCodeChallengeSkipQuery = exports.useSendMfaCodeChallengeQuery = exports.useSendSmsCodeQuery = exports.useAskForSmsCodeQuery = exports.useSendMfaCodeQuery = exports.useActivateUserQuery = exports.confirmTokenQuery = exports.useRegisterQuery = exports.getSamlQuery = exports.useResetPasswordQuery = exports.useForgotPasswordQuery = exports.useLoginQuery = exports.authStatusQuery = exports.useSessionLogoutQuery = exports.sessionKeys = void 0;
const react_query_1 = require("@tanstack/react-query");
const sessionRepository_1 = require("../repositories/sessionRepository");
const Root_1 = require("../router/Root");
exports.sessionKeys = {
    getStatus: () => ['status'],
    getSaml: (email) => ['saml', email],
    getActivateUser: (userId, activationToken) => ['userId', userId, 'activationToken', activationToken],
    confirmToken: (activationToken, userId) => ['activationToken', activationToken, 'userId', userId],
};
const useSessionLogoutQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: sessionRepository_1.SessionRepository.logout,
        onSettled: () => {
            Root_1.queryClient.setDefaultOptions({
                queries: {
                    enabled: false,
                },
            });
            Root_1.queryClient.clear();
            Root_1.queryClient.removeQueries();
        },
    });
};
exports.useSessionLogoutQuery = useSessionLogoutQuery;
exports.authStatusQuery = (0, react_query_1.queryOptions)({
    queryKey: exports.sessionKeys.getStatus(),
    queryFn: sessionRepository_1.SessionRepository.getStatus,
    retry: false,
});
const useLoginQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: (data) => sessionRepository_1.SessionRepository.login(data),
    });
};
exports.useLoginQuery = useLoginQuery;
const useForgotPasswordQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: (data) => sessionRepository_1.SessionRepository.forgotPassword(data),
    });
};
exports.useForgotPasswordQuery = useForgotPasswordQuery;
const useResetPasswordQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: (data) => sessionRepository_1.SessionRepository.resetPassword(data),
    });
};
exports.useResetPasswordQuery = useResetPasswordQuery;
const getSamlQuery = (email) => ({
    queryKey: [exports.sessionKeys.getSaml(email)],
    queryFn: () => sessionRepository_1.SessionRepository.getSaml(email),
});
exports.getSamlQuery = getSamlQuery;
const useRegisterQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: (data) => sessionRepository_1.SessionRepository.register(data),
    });
};
exports.useRegisterQuery = useRegisterQuery;
const confirmTokenQuery = (userId, activationToken) => (0, react_query_1.queryOptions)({
    queryKey: exports.sessionKeys.getActivateUser(userId, activationToken),
    queryFn: () => sessionRepository_1.SessionRepository.confirmToken({ userId, activationToken }),
    enabled: !!(userId && activationToken),
});
exports.confirmTokenQuery = confirmTokenQuery;
const useActivateUserQuery = () => (0, react_query_1.useMutation)({
    mutationFn: (options) => sessionRepository_1.SessionRepository.activateUser(options),
});
exports.useActivateUserQuery = useActivateUserQuery;
const useSendMfaCodeQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: (data) => sessionRepository_1.SessionRepository.sendMfaCode(data),
    });
};
exports.useSendMfaCodeQuery = useSendMfaCodeQuery;
const useAskForSmsCodeQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: sessionRepository_1.SessionRepository.askForSmsCode,
    });
};
exports.useAskForSmsCodeQuery = useAskForSmsCodeQuery;
const useSendSmsCodeQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: (data) => sessionRepository_1.SessionRepository.sendSmsCode(data),
    });
};
exports.useSendSmsCodeQuery = useSendSmsCodeQuery;
const useSendMfaCodeChallengeQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: (data) => sessionRepository_1.SessionRepository.sendMfaCodeChallenge(data),
    });
};
exports.useSendMfaCodeChallengeQuery = useSendMfaCodeChallengeQuery;
const useSendMfaCodeChallengeSkipQuery = () => {
    return (0, react_query_1.useMutation)({
        mutationFn: sessionRepository_1.SessionRepository.sendMfaCodeChallengeSkip,
    });
};
exports.useSendMfaCodeChallengeSkipQuery = useSendMfaCodeChallengeSkipQuery;
const useRequestActivationWithTemporaryPasswordQuery = () => (0, react_query_1.useMutation)({
    mutationFn: (options) => sessionRepository_1.SessionRepository.requestActivationWithTemporaryPasswordQuery(options),
});
exports.useRequestActivationWithTemporaryPasswordQuery = useRequestActivationWithTemporaryPasswordQuery;
