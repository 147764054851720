// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
const SvgStatusWarningOnAmber = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M9 11.75A1.25 1.25 0 1 1 7.75 13 1.25 1.25 0 0 1 9 11.75m-.9-2h1.8v-6H8.1Z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
export default SvgStatusWarningOnAmber;
