"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiFormControlLabelOverrides = void 0;
const material_1 = require("@mui/material");
exports.muiFormControlLabelOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                width: '100%',
                margin: 0,
                padding: `0 0 0 ${theme.tsGoUnit.tsUnitQuarter}`,
                border: '1px solid transparent',
                ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                    ? {
                        padding: 0,
                    }
                    : {}),
                '&:hover': {
                    border: '1px solid hsla(199,100%,50%,.25)',
                    boxShadow: '0 0 0 1px hsla(199,100%,50%,.75)',
                    borderRadius: theme.shape.borderRadius,
                },
                [`.${material_1.formControlLabelClasses.label}`]: {
                    marginRight: 'auto',
                    // fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
                },
            };
        },
    },
};
