// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
const SvgStatusYesThickOnLight = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M5.92 15.94.11 10.13l1.27-1.27 4.54 4.54 10.7-10.71 1.27 1.28z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
export default SvgStatusYesThickOnLight;
