// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
const SvgStatusErrorOnDark = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="M17.89 15.8 9.69 1.68a.8.8 0 0 0-1.38 0L.11 15.8A.8.8 0 0 0 .8 17h16.4a.8.8 0 0 0 .69-1.2M8.1 5h1.8v6H8.1Zm.9 9.83a1.25 1.25 0 1 1 1.25-1.25A1.25 1.25 0 0 1 9 14.83"
			style={{
				fill: '#fff',
			}}
		/>
	</svg>
);
export default SvgStatusErrorOnDark;
