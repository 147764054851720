"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiOutlinedInputOverrides = void 0;
const material_1 = require("@mui/material");
exports.muiOutlinedInputOverrides = {
    styleOverrides: {
        root: ({ theme }) => {
            return {
                boxSizing: 'border-box !important',
                [`& .${material_1.outlinedInputClasses.input}`]: {
                    backgroundColor: theme.palette.background.paper,
                    height: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? '36px !important' : '30px !important',
                    padding: '4px 10px',
                    boxSizing: 'border-box',
                    lineHeight: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? '28px !important' : '22px !important',
                    '&.MuiInputBase-readOnly': {
                        backgroundColor: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'hsla(0, 0%, 85%, 1)' : 'inherit',
                        color: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'hsla(0, 0%, 59%, 1) !important' : 'inherit',
                    },
                },
                '&:hover': {
                    [`& .${material_1.outlinedInputClasses.notchedOutline}`]: {
                        border: '1px solid hsla(199,100%,50%,.25)',
                        boxShadow: '0 0 0 1px hsla(199,100%,50%,.75)',
                        borderRadius: theme.shape.borderRadius,
                    },
                },
                [`& .${material_1.outlinedInputClasses.notchedOutline}`]: {
                    width: '100%',
                    boxSizing: 'border-box',
                    top: 0,
                    legend: {
                        display: 'none',
                    },
                },
            };
        },
    },
};
