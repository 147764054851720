// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
const SvgStatusNoThickOnLight = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<path
			d="m16.64 2.64-1.28-1.28L9 7.73 2.64 1.36 1.36 2.64 7.73 9l-6.37 6.36 1.28 1.28L9 10.27l6.36 6.37 1.28-1.28L10.27 9z"
			style={{
				fill: '#333',
			}}
		/>
	</svg>
);
export default SvgStatusNoThickOnLight;
