"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const CardSkeletonLoader = ({ count }) => {
    const renderCardSkeleton = () => ((0, jsx_runtime_1.jsx)(material_1.Grid2, { size: {
            xs: 12,
            sm: 6,
            md: 4,
        }, children: (0, jsx_runtime_1.jsxs)(material_1.Box, { className: "MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-18bgnh6-MuiPaper-root-MuiCard-root", style: { margin: '8px', padding: '16px' }, children: [(0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text", width: "60%" }), (0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "rectangular", height: 20, style: { marginBottom: '8px' } }), (0, jsx_runtime_1.jsxs)(material_1.Box, { sx: {
                        display: 'flex',
                        justifyContent: 'space-between',
                    }, children: [(0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text", width: "40%" }), (0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text", width: "20%" })] }), (0, jsx_runtime_1.jsxs)(material_1.Box, { sx: {
                        mt: 2,
                    }, children: [(0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text", width: "80%" }), (0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text", width: "60%" })] }), (0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "rectangular", height: 20, style: { marginTop: '16px' } }), (0, jsx_runtime_1.jsxs)(material_1.Box, { sx: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 2,
                    }, children: [(0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text", width: "30%" }), (0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text", width: "30%" }), (0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "text", width: "30%" })] })] }) }, crypto.randomUUID()));
    return ((0, jsx_runtime_1.jsx)(material_1.Grid2, { container: true, spacing: 2, children: Array.from({ length: count }, renderCardSkeleton) }));
};
exports.default = CardSkeletonLoader;
