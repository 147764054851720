"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.invitationInitialState = void 0;
exports.invitationSlice = invitationSlice;
const initialState = {
    username: '',
    firstName: '',
    lastName: '',
    isFormLoading: false,
};
exports.invitationInitialState = {
    invitation: initialState,
};
function invitationSlice(set) {
    return {
        ...exports.invitationInitialState,
        setInvitationUserName: (username) => {
            set((state) => {
                state.invitation.username = username;
                return state;
            }, false, '[setInvitationUsername]');
        },
        setInvitationFirstName: (firstName) => {
            set((state) => {
                state.invitation.firstName = firstName;
                return state;
            }, false, '[setInvitationFirstName]');
        },
        setInvitationLastName: (lastName) => {
            set((state) => {
                state.invitation.lastName = lastName;
                return state;
            }, false, '[setInvitationLastName]');
        },
        setInvitationFormLoading: (isFormLoading) => {
            set((state) => {
                state.invitation.isFormLoading = isFormLoading;
                return state;
            }, false, '[setInvitationFormLoading]');
        },
        resetInvitationForm: () => {
            set((state) => {
                state.invitation = initialState;
                return state;
            }, false, '[resetInvitationForm]');
        },
    };
}
