export const validator = {
	errors: {
		EXCEEDED_LENGTH: 'EXCEEDED_LENGTH',
	},
};

export const isCompanyNameLengthValid = name => {
	if (name === '') {
		return true;
	}
	const nameLength = 40;
	if (name.length > nameLength) {
		return !validator.errors.EXCEEDED_LENGTH;
	}
	return true;
};
