"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledGraphTabs = exports.Info = exports.Amount = exports.OverviewItem = exports.OverviewTemplate = exports.OverviewContainer = exports.StyledSubTitle = void 0;
const material_1 = require("@mui/material");
exports.StyledSubTitle = (0, material_1.styled)(material_1.Typography)(ownerState => {
    const { theme } = ownerState;
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.medium),
        fontWeight: theme.typography.tsFontWeight.semiBold,
        padding: '0 0 10px 0',
        lineHeight: '25px',
    };
});
exports.OverviewContainer = (0, material_1.styled)(material_1.Box)(() => {
    return {
        border: '1px solid #d8e2e5',
        backgroundColor: 'hsl(0, 0%, 100%)',
        boxShadow: '0 5px 10px 0 rgba(88, 124, 141, 0.1)',
        margin: '0 0 20px 0',
    };
});
exports.OverviewTemplate = (0, material_1.styled)(material_1.Box)(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
        height: '180px',
        overflowWrap: 'anywhere',
    };
});
exports.OverviewItem = (0, material_1.styled)(material_1.Box)(() => {
    return { display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '0 0 50%' };
});
exports.Amount = (0, material_1.styled)(material_1.Typography)(() => {
    return {
        fontSize: '24px',
        margin: '0',
        padding: '10px 0 5px 0',
    };
});
exports.Info = (0, material_1.styled)(material_1.Typography)(() => {
    return {
        fontSize: '11px',
    };
});
exports.StyledGraphTabs = (0, material_1.styled)(material_1.Tabs)(ownerState => {
    const { theme } = ownerState;
    return {
        button: {
            textTransform: 'capitalize',
            '&.MuiTab-root': {
                color: theme.palette.tsGoColor.black,
                '&:hover': {
                    textDecoration: 'none',
                },
            },
        },
    };
});
