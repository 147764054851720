"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_query_1 = require("@tanstack/react-query");
const react_1 = require("react");
const appConstants_1 = require("../../../../../constants/appConstants");
const strings_1 = require("../../../../../constants/strings");
const FormLabel_1 = require("../../../../../material-ui-theme/components/styled/FormLabel");
const codingQuery_1 = require("../../../../../queries/codingQuery");
const zustandStore_1 = require("../../../../../zustandStore");
const CODING_LIST = appConstants_1.codingListConstants.codingList;
const CODING_LIST_PUBLISHED = appConstants_1.codingListConstants.codingListPublished;
const PROVIDER_MAPPING = appConstants_1.codingListConstants.providerMapping;
function findMatchingGroup(codingListObj, providerMappingsObj, currentCodingListSelectedId) {
    if (!providerMappingsObj) {
        return null;
    }
    for (const obj1 of codingListObj) {
        if (obj1.group && obj1.ID === currentCodingListSelectedId) {
            const matchingKey = Object.keys(providerMappingsObj).find(key => providerMappingsObj[key] === obj1.group);
            if (matchingKey) {
                return { [matchingKey]: providerMappingsObj[matchingKey] };
            }
        }
    }
    return null;
}
function getDisabled(codingEntries, selectedCodingList) {
    const selectedCodingEntry = codingEntries?.find(entry => entry?.code === selectedCodingList.id);
    if (!selectedCodingEntry || !selectedCodingEntry.values) {
        return true;
    }
    return selectedCodingEntry.values.length === 0;
}
const CodingHeaderSection = () => {
    const { setCurrentCodingListSelected, setCurrentSelectedProviderMapOption } = (0, zustandStore_1.useZustand)();
    const currentSelectedProviderMapOption = (0, zustandStore_1.useZustand)(state => state.providerMapping.currentSelectedProviderMapOption);
    const providerMappingOptions = (0, zustandStore_1.useZustand)(state => state.providerMapping.options);
    const saveProviderFields = (0, codingQuery_1.useSaveProviderFieldsQuery)();
    const { data: codingLists = [] } = (0, react_query_1.useQuery)((0, codingQuery_1.getCodingListsQuery)({ skipDisabled: false }));
    const { data: codingProviderMapping } = (0, codingQuery_1.useGetProviderMappingQuery)();
    const { data: codingProviderFields } = (0, codingQuery_1.useGetProviderFieldsQuery)();
    const enableCodingListQuery = (0, codingQuery_1.useEnableCodingListQuery)();
    const disableCodingListQuery = (0, codingQuery_1.useDisableCodingListQuery)();
    const { data: codingEntries = [] } = (0, react_query_1.useQuery)((0, codingQuery_1.getCodingListsEntriesQuery)({
        codingLists,
    }));
    const currentCodingListSelected = (0, zustandStore_1.useZustand)(state => state.codingList.currentCodingListSelected);
    const onProviderMappingChange = (_key, param) => {
        const codingList = param.code;
        const newProviderField = param.value;
        const providerFields = {};
        for (const key of Object.keys(codingProviderFields)) {
            if (codingProviderFields[key] !== codingList) {
                providerFields[key] = codingProviderFields[key];
            }
        }
        if (newProviderField !== appConstants_1.codingListConstants.providerMappingNoneOption) {
            providerFields[newProviderField] = codingList;
        }
        saveProviderFields.mutate({ providerFields });
    };
    const onPublishCodingListChange = (checked) => {
        const codingListCode = currentCodingListSelected.id;
        if (checked) {
            enableCodingListQuery.mutate({ codingListCode }, {
                onSuccess: () => {
                    setCurrentCodingListSelected({ ...currentCodingListSelected, enabled: checked });
                },
            });
        }
        else {
            disableCodingListQuery.mutate({ codingListCode }, {
                onSuccess: () => {
                    setCurrentCodingListSelected({ ...currentCodingListSelected, enabled: checked });
                },
            });
        }
    };
    (0, react_1.useEffect)(() => {
        if (codingLists?.length > 0) {
            if (currentCodingListSelected.id) {
                const selectedList = codingLists?.find(item => item.ID === currentCodingListSelected.id);
                if (!selectedList) {
                    return;
                }
                setNewCodingList(setCurrentCodingListSelected, selectedList);
            }
            else {
                if (codingLists.length > 0 && codingLists[0]) {
                    setNewCodingList(setCurrentCodingListSelected, codingLists[0]);
                }
            }
        }
    }, [codingLists, currentCodingListSelected.id]);
    (0, react_1.useEffect)(() => {
        const result = findMatchingGroup(codingLists, codingProviderFields, currentCodingListSelected.id);
        if (result) {
            const key = Object.keys(result)[0];
            setCurrentSelectedProviderMapOption({ label: 'PROVIDER_MAPPING', id: key || 'Not Mapped' });
        }
        else {
            setCurrentSelectedProviderMapOption({
                label: 'PROVIDER_MAPPING',
                id: appConstants_1.codingListConstants.providerMappingNoneOption,
            });
        }
    }, [codingLists, codingProviderFields, currentCodingListSelected.id]);
    const onChangeCodingList = (value) => {
        const selectedList = codingLists?.find(item => item.ID === value);
        if (!selectedList) {
            return;
        }
        setNewCodingList(setCurrentCodingListSelected, {
            ...selectedList,
            name: selectedList.name,
            ID: value,
            code: value,
            enabled: selectedList.enabled,
        });
        const result = findMatchingGroup([selectedList], codingProviderFields, currentCodingListSelected.id);
        if (result) {
            const key = Object.keys(result)[0];
            setCurrentSelectedProviderMapOption({ label: 'PROVIDER_MAPPING', id: key || 'Not Mapped' });
        }
        else {
            setCurrentSelectedProviderMapOption({
                label: 'PROVIDER_MAPPING',
                id: appConstants_1.codingListConstants.providerMappingNoneOption,
            });
        }
    };
    const isPublishedDisabled = (0, react_1.useMemo)(() => getDisabled(codingEntries, currentCodingListSelected), [codingEntries, currentCodingListSelected]);
    return ((0, jsx_runtime_1.jsxs)(material_1.Grid2, { container: true, spacing: 2, sx: {
            pb: 3,
        }, children: [codingLists?.length > 0 ? ((0, jsx_runtime_1.jsx)(material_1.Grid2, { size: 4, children: (0, jsx_runtime_1.jsxs)(material_1.FormControl, { sx: { pt: 1, paddingLeft: 0 }, children: [(0, jsx_runtime_1.jsx)(FormLabel_1.StyledFormLabel, { children: strings_1.companySettingsCodingStrings.CODING_LIST }), (0, jsx_runtime_1.jsx)(material_1.Select, { "data-testid": "coding-list-selector", name: CODING_LIST, value: currentCodingListSelected.id || codingLists?.[0]?.ID || '', onChange: event => onChangeCodingList(event.target.value), children: codingLists?.map(value => ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: value.ID, children: value.name }, value.ID))) })] }) })) : null, codingProviderMapping === 'AMEX' && ((0, jsx_runtime_1.jsx)(material_1.Grid2, { size: 4, children: (0, jsx_runtime_1.jsxs)(material_1.FormControl, { sx: { pt: 1 }, children: [(0, jsx_runtime_1.jsx)(FormLabel_1.StyledFormLabel, { children: strings_1.companySettingsCodingStrings.PROVIDER_MAPPING }), (0, jsx_runtime_1.jsx)(material_1.Select, { name: PROVIDER_MAPPING, value: currentSelectedProviderMapOption.id, onChange: event => {
                                setCurrentSelectedProviderMapOption({ label: event.target.name, id: event.target.value });
                                onProviderMappingChange(event.target.name, {
                                    code: currentCodingListSelected.id,
                                    value: event.target.value,
                                });
                            }, children: providerMappingOptions?.map(providerMappingOption => ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: providerMappingOption.id, children: providerMappingOption.label }, providerMappingOption.id))) })] }) })), (0, jsx_runtime_1.jsx)(material_1.Grid2, { sx: {
                    ml: 'auto',
                }, size: {
                    xs: 2,
                    md: 1,
                }, children: (0, jsx_runtime_1.jsx)(material_1.FormGroup, { sx: { pb: 0 }, children: (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { sx: { boxShadow: 'none !important', border: 'none !important' }, name: CODING_LIST_PUBLISHED, control: (0, jsx_runtime_1.jsx)(material_1.Switch, { "data-testid": CODING_LIST_PUBLISHED, color: "primary", checked: currentCodingListSelected.enabled, onChange: event => onPublishCodingListChange(event.target.checked), disabled: isPublishedDisabled }), label: strings_1.companySettingsCodingStrings.CODING_LIST_PUBLISHED, labelPlacement: "start" }) }) })] }));
};
exports.default = CodingHeaderSection;
function setNewCodingList(setCurrentCodingListSelected, selectedList) {
    setCurrentCodingListSelected({
        name: selectedList.name || '',
        id: selectedList.ID || '',
        code: selectedList.code || '',
        enabled: selectedList.enabled,
    });
}
