"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledFooter = void 0;
const material_1 = require("@mui/material");
exports.StyledFooter = (0, material_1.styled)(material_1.Box)(({ theme }) => ({
    '--footerHeight': '60px',
    // backgroundColor: '#eff2f5',
    backgroundColor: theme.palette.tsGoColor.white,
    position: 'relative',
    transition: 'opacity 0.2s',
    height: 'var(--footerHeight)',
    maxHeight: 'var(--footerHeight)',
    minHeight: 'var(--footerHeight)',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    justifyContent: 'flex-end',
    zIndex: '2000',
    marginTop: 'auto',
    width: '100%',
    boxSizing: 'border-box',
    '&::after': {
        height: '1px',
        position: 'absolute',
        left: 0,
        right: 0,
        pointerEvents: 'none',
        backgroundColor: '#cad7dc',
        content: '""',
        top: 0,
    },
}));
