"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledLink = void 0;
const material_1 = require("@mui/material");
exports.StyledLink = (0, material_1.styled)(material_1.Link)(ownerState => {
    const { theme, 'ts-data': tsData } = ownerState;
    const commonAfterStyles = {
        position: 'absolute',
        backgroundColor: window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.palette.hsbcColor.green
            : theme.palette.tsGoColor.green,
        borderRadius: '3px',
        height: theme.tsGoUnit.tsUnit,
        width: theme.tsGoUnit.tsUnitOneAndHalf,
        right: theme.tsGoUnit.tsUnit,
        transform: 'translateY(-50%)',
        textAlign: 'center',
        top: '50%',
        lineHeight: theme.tsGoUnit.tsUnit,
        opacity: 0,
        ...(window.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                borderRadius: 0,
            }
            : {}),
    };
    return {
        '@keyframes fadeInExpanded': {
            '0%': { opacity: 0 },
            '100%': { opacity: 1 },
        },
        '@keyframes fadeInCollapsed': {
            '0%': { opacity: 0 },
            '100%': { opacity: 1 },
        },
        '&.hasApprovalIcon.expanded, &.hasApprovalIcon.isMobileMenu': {
            position: 'relative',
            flexBasis: '100%',
            '::after': {
                ...commonAfterStyles,
                content: `"${tsData}"`,
                fontSize: theme.typography.tsFontSize.small,
                color: window.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'white' : theme.palette.tsGoColor.black,
                animation: 'fadeInExpanded 0.3s ease-in-out 0.3s forwards',
            },
        },
        '&.hasApprovalIcon.collapsed:not(.isMobileMenu)': {
            position: 'relative',
            '::after': {
                ...commonAfterStyles,
                content: '""',
                borderRadius: '100%',
                height: theme.tsGoUnit.tsUnitHalf,
                width: theme.tsGoUnit.tsUnitHalf,
                bottom: `calc(-${theme.tsGoUnit.tsUnitHalf} + 50%)`,
                right: `calc(-${theme.tsGoUnit.tsUnitHalf} + 50%)`,
                transform: 'translate(50%, 50%)',
                animation: 'fadeInCollapsed 0.3s ease-in-out 0.3s forwards',
            },
        },
    };
});
