"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiLinkOverrides = void 0;
const tsGoThemeVars_1 = require("../tsGoThemeVars");
function getSidemenuLinkSpan(theme) {
    return {
        textDecoration: 'none',
        justifyContent: 'normal !important',
        minHeight: theme.tsGoUnit.tsUnitTriple,
        padding: '0 0 0 16px !important',
        minWidth: 0,
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        color: theme.palette.tsGoColor.grayLightest,
        fontWeight: tsGoThemeVars_1.tsGoTypography.tsFontWeight.bold,
        flexBasis: '100%',
        alignItems: 'center',
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                padding: '0 0 0 15px !important',
            }
            : {}),
        i: {
            position: 'static',
            left: tsGoThemeVars_1.tsGoUnit.tsUnitOneAndHalf,
            transition: 'left 0.3s ease-out',
            flexShrink: 0,
        },
    };
}
function getSidemenuFooterLink(theme) {
    return {
        margin: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? '15px 5px' : '15px',
        textDecoration: 'none',
        color: tsGoThemeVars_1.goPalette.tsGoColor.gray,
        '&:last-child': {
            paddingRight: theme.tsGoUnit.tsUnitHalf,
        },
    };
}
function getSideMenuSwitcherTeamLink() {
    return {
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none !important',
        },
    };
}
function getRequestMenuItemLinkCss() {
    return {
        color: 'white',
        textDecoration: 'none',
        marginLeft: '15px',
    };
}
function getLandingFooterLinksStyle(theme) {
    return {
        color: theme.palette.tsGoColor.grayDark,
        margin: '0 10px',
    };
}
exports.muiLinkOverrides = {
    styleOverrides: {
        root: ({ theme }) => ({
            variants: [
                {
                    props: {
                        'ts-variant': 'sideMenuLink',
                    },
                    style: getSidemenuLinkSpan(theme),
                },
                {
                    props: {
                        'ts-variant': 'sideMenuFooterLink',
                    },
                    style: getSidemenuFooterLink(theme),
                },
                {
                    props: {
                        'ts-variant': 'sideMenuSwitcherTeamLink',
                    },
                    style: getSideMenuSwitcherTeamLink(),
                },
                {
                    props: {
                        'ts-variant': 'requestMenuItemLink',
                    },
                    style: getRequestMenuItemLinkCss(),
                },
                {
                    props: {
                        'ts-variant': 'footer-links',
                    },
                    style: getLandingFooterLinksStyle(theme),
                },
            ],
        }),
    },
};
