"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordLoginInitialState = void 0;
exports.passwordLoginSlice = passwordLoginSlice;
exports.passwordLoginInitialState = {
    passwordLogin: {
        email: '',
        password: '',
        isFormLoading: false,
        error: null,
        isMfaLoginRequired: false,
        isMfaAppSetupRequired: false,
        isMfaSmsSetupRequired: false,
        isPhoneSetupRequired: false,
        formState: {
            authCode: '',
        },
    },
};
function passwordLoginSlice(set) {
    return {
        ...exports.passwordLoginInitialState,
        setEmail: (email) => {
            set((state) => {
                state.passwordLogin.email = email;
                return state;
            }, false, '[setEmail]');
        },
        setPassword: (password) => {
            set((state) => {
                state.passwordLogin.password = password;
                return state;
            }, false, '[setPassword]');
        },
        setIsFormLoading: (isLoading) => {
            set((state) => {
                state.passwordLogin.isFormLoading = isLoading;
                return state;
            }, false, '[setIsFormLoading]');
        },
        setError: (error) => {
            set((state) => {
                state.passwordLogin.error = error;
                return state;
            }, false, '[setError]');
        },
        setIsMfaLoginRequired: (isMfaLoginRequired) => {
            set((state) => {
                state.passwordLogin.isMfaLoginRequired = isMfaLoginRequired;
                return state;
            }, false, '[setIsMfaLoginRequired]');
        },
        setIsMfaAppSetupRequired(isMfaAppSetupRequired) {
            set((state) => {
                state.passwordLogin.isMfaAppSetupRequired = isMfaAppSetupRequired;
                return state;
            }, false, '[setIsMfaAppSetupRequired]');
        },
        setIsMfaSmsSetupRequired(isMfaSmsSetupRequired) {
            set((state) => {
                state.passwordLogin.isMfaSmsSetupRequired = isMfaSmsSetupRequired;
                return state;
            }, false, '[setIsMfaSmsSetupRequired]');
        },
        setIsPhoneSetupRequired(isPhoneSetupRequired) {
            set((state) => {
                state.passwordLogin.isPhoneSetupRequired = isPhoneSetupRequired;
                return state;
            }, false, '[setIsPhoneSetupRequired]');
        },
        setFormState(formState) {
            set((state) => {
                state.passwordLogin.formState = formState;
                return state;
            }, false, '[setFormState]');
        },
        resetPasswordLoginState() {
            set((state) => {
                state = exports.passwordLoginInitialState;
                return state;
            }, false, '[resetPasswordLoginState]');
        },
    };
}
