"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiTypographyOverrides = void 0;
const tsGoThemeVars_1 = require("../tsGoThemeVars");
function getH6Style(theme) {
    return {
        margin: '0',
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.big),
        display: 'block',
        position: 'sticky',
        backgroundColor: 'white',
        inset: '0',
        padding: '14px 20px 5px',
        zIndex: '10',
        fontWeight: theme.typography.tsFontWeight.default,
        '&::after': {
            content: '""',
            height: '1px',
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
            pointerEvents: 'none',
            backgroundColor: '#cad7dc',
        },
    };
}
function getSubtitle1Style(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.tsFontWeight.default,
        marginBottom: '0.5rem',
        marginTop: '20px',
        paddingLeft: '0',
    };
}
function getSubtitle2Style(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        fontWeight: theme.typography.tsFontWeight.bold,
    };
}
function getBody1Style(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.tsFontWeight.default,
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontSize: '12px',
                fontWeight: 400,
                color: '#000000',
            }
            : {}),
    };
}
function getActiveButtonStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        color: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.palette.hsbcColor.green
            : theme.palette.tsGoColor.green,
    };
}
function getInactiveButtonStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        color: theme.palette.tsGoColor.orange,
    };
}
function getBody2Style(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
    };
}
function getTableCellTitleStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.fontWeightRegular,
        span: {
            color: 'hsl(198, 20%, 59%)',
            fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
            fontStyle: 'normal',
        },
        width: '80px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
}
function getTableCellTitleNameStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.fontWeightRegular,
        width: '300px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
}
function getTableCellSubtitleStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.tsGoColor.grayDark,
        margin: 0,
    };
}
function getTableCellSubtitleStyleSC(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.tsGoColor.grayDark,
        margin: 0,
        width: '80px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
}
function getTableHeaderCellTitleStyle(theme) {
    // this is used in Table to the title of the column header cell
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'uppercase',
        color: theme.palette.tsGoColor.black,
        alignSelf: 'center',
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontSize: '12px',
                fontWeight: 500,
                color: '#000000',
                textTransform: 'none',
            }
            : {}),
    };
}
function getNoListStyle() {
    return {
        color: 'hsl(198, 20%, 49%)',
        textAlign: 'center',
    };
}
function getImgSpanStyle(theme) {
    return {
        paddingLeft: theme.tsGoUnit.tsUnitDouble,
        width: '100%',
        display: 'block',
        fontWeight: theme.typography.tsFontWeight.bold,
        paddingRight: theme.tsGoUnit.tsUnit,
    };
}
function getImgSideSpanStyle(theme) {
    return {
        width: '100%',
        display: 'block',
        paddingRight: theme.tsGoUnit.tsUnit,
        fontSize: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.typography.tsFontSize.mini
            : theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.tsFontWeight.default,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#fff',
            }
            : {}),
    };
}
function getImgSubStyle(theme) {
    return {
        paddingLeft: theme.tsGoUnit.tsUnitDouble,
        textTransform: 'none',
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        fontWeight: theme.typography.tsFontWeight.default,
        lineHeight: theme.tsGoUnit.tsUnit,
    };
}
function getImgSideSubStyle(theme) {
    return {
        textTransform: 'none',
        fontSize: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.typography.tsFontSize.mini
            : theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        fontWeight: theme.typography.tsFontWeight.default,
        lineHeight: theme.tsGoUnit.tsUnit,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#fff',
            }
            : {}),
    };
}
function getH3Style(theme) {
    return {
        fontWeight: `${theme.typography.tsFontWeight.bold} !important`,
        fontSize: `${theme.typography.pxToRem(theme.typography.tsFontSize.big)} !important`,
        lineHeight: `${theme.tsGoUnit.tsUnit} !important`,
        marginTop: '25px !important',
        marginBottom: `${theme.tsGoUnit.tsUnitHalf} !important`,
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontSize: '1rem !important',
                fontWeight: `${theme.typography.tsFontWeight.default} !important`,
                color: 'black',
            }
            : {}),
    };
}
function getNavigationDefaultStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        padding: `${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnitHalf}`,
        color: theme.palette.tsGoColor.grayDark,
        fontWeight: theme.typography.tsFontWeight.semiBold,
    };
}
function getNavigationActiveStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        padding: `${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnitHalf}`,
        fontWeight: theme.typography.tsFontWeight.semiBold,
        borderBottom: `2px solid ${theme.palette.tsGoColor.black}`,
        color: theme.palette.tsGoColor.black,
    };
}
function getTitleStyle(theme) {
    return {
        fontWeight: `${theme.typography.tsFontWeight.semiBold} !important`,
        fontSize: `${theme.typography.pxToRem(theme.typography.tsFontSize.big)} !important`,
        color: theme.palette.tsGoColor.black,
        textAlign: 'center',
    };
}
function getTitleInfoStyle(theme) {
    return {
        fontWeight: `${theme.typography.tsFontWeight.default} !important`,
        fontSize: `${theme.typography.pxToRem(theme.typography.tsFontSize.medium)} !important`,
        color: theme.palette.tsGoColor.black,
        textAlign: 'center',
        marginTop: '20px',
    };
}
function getUserAvatarStyle(theme) {
    return {
        display: 'inline-block',
        height: theme.tsGoUnit.tsUnitDouble,
        width: theme.tsGoUnit.tsUnitDouble,
        borderRadius: theme.tsGoUnit.tsUnit,
        background: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.palette.hsbcColor.primary
            : 'linear-gradient(-225deg, hsl(325, 98%, 33%) 27%, #590060 100%)',
        fontSize: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? '10px'
            : theme.typography.pxToRem(theme.typography.tsFontSize.big),
        fontWeight: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? theme.typography.tsFontWeight.default
            : theme.typography.tsFontWeight.bolder,
        lineHeight: theme.tsGoUnit.tsUnitDouble,
        textAlign: 'center',
        color: 'hsl(0, 0%, 100%)',
    };
}
function getSidemenuSpan(theme) {
    return {
        paddingTop: theme.tsGoUnit.tsUnit,
        marginLeft: '50px',
        transition: 'opacity 0.2s ease-out 0.1s',
        color: theme.palette.tsGoColor.grayLightest,
        fontWeight: theme.typography.tsFontWeight.bold,
        fontSize: theme.typography.tsFontSize.medium,
    };
}
function getSidemenuItemSpan(theme) {
    return {
        paddingLeft: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? theme.tsGoUnit.tsUnitHalf : theme.tsGoUnit.tsUnit,
        display: 'flex',
        alignSelf: 'center',
        fontWeight: theme.typography.tsFontWeight.semiBold,
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.medium, tsGoThemeVars_1.defaultMUiFontsizePlusTwo),
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontWeight: 500,
                fontSize: theme.typography.tsFontSize.default,
                color: 'white !important',
            }
            : {}),
    };
}
function getMyRequestItemSpan(theme) {
    return {
        fontWeight: theme.typography.tsFontWeight.boldest,
        marginLeft: `${theme.tsGoUnit.tsUnitOneAndHalf} !important`,
        textWrap: 'pretty',
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#fff',
            }
            : {}),
    };
}
function getMyRequestItemSecondarySpan(theme) {
    return {
        fontWeight: theme.typography.tsFontWeight.default,
        marginLeft: `${theme.tsGoUnit.tsUnitOneAndHalf} !important`,
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#fff',
            }
            : {}),
    };
}
function getSpanStyle(theme) {
    return {
        fontSize: theme.typography.tsFontSize.mini,
        fontWeight: theme.typography.tsFontWeight.semiBold,
        textTransform: 'uppercase',
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontWeight: 500,
                fontSize: '12px',
                color: '#000000',
                textTransform: 'none',
            }
            : {}),
    };
}
const defaultHeaderStyle = (theme) => ({
    fontSize: theme.typography.tsFontSize.big,
    fontWeight: theme.typography.tsFontWeight.default,
});
function getHeaderStyle(theme) {
    return {
        ...defaultHeaderStyle(theme),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
}
function getHeaderStyleNoTextTrunc(theme) {
    return defaultHeaderStyle(theme);
}
function getSideMenuFooterDetailStyle(theme) {
    return {
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.tsGoColor.white,
        maxWidth: '180px',
    };
}
function getSideMenuHeaderIconStyle(theme) {
    const defaultStyles = {
        alignItems: 'center',
        borderRadius: theme.tsGoUnit.tsUnitOneAndHalf,
        flex: 1,
        height: theme.tsGoUnit.tsUnitOneAndHalf,
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
        top: '15px',
        transition: 'var(--collapseButtonTransition)',
        width: theme.tsGoUnit.tsUnitOneAndHalf,
        zIndex: 1,
        overflow: 'hidden',
    };
    return { ...defaultStyles };
}
function getTeamSwitcherHeaderTitle(theme) {
    return {
        borderBottom: 'hsl(204, 25%, 8%) 1px solid',
        color: 'hsl(0, 0%, 100%)',
        fontSize: '16px',
        fontWeight: theme.typography.tsFontWeight.bold,
        height: theme.tsGoUnit.tsUnit,
        marginTop: '1px',
        padding: theme.tsGoUnit.tsUnit,
    };
}
function getTeamName(theme) {
    return {
        fontWeight: theme.typography.tsFontWeight.default,
        fontSize: theme.typography.tsFontSize.default,
        paddingLeft: theme.tsGoUnit.tsUnit,
        paddingRight: theme.tsGoUnit.tsUnitDouble,
        marginLeft: 0,
        width: '250px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: 'white',
            }
            : {}),
    };
}
function getRoleName(theme) {
    return {
        paddingLeft: theme.tsGoUnit.tsUnit,
        paddingRight: theme.tsGoUnit.tsUnitDouble,
        marginLeft: 0,
        color: theme.palette.tsGoColor.grayDark,
        fontWeight: theme.typography.tsFontWeight.bold,
        fontSize: '11px',
        textTransform: 'uppercase',
    };
}
function getMultiLineStyle() {
    return {
        position: 'absolute',
        left: '100px',
        top: '50%',
        transform: 'translateY(-50%)',
    };
}
function getSecondaryDivStyle(theme) {
    return {
        fontWeight: theme.typography.tsFontWeight.bold,
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.small),
        color: theme.palette.tsGoColor.grayDark,
        textTransform: 'uppercase',
    };
}
function getSelectItemStyle(theme) {
    return {
        fontSize: theme.typography.tsFontSize.default,
        fontWeight: theme.typography.tsFontWeight.boldest,
    };
}
function getRoleNameStyle(theme) {
    return {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: theme.typography.tsFontWeight.bold,
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#ffffff',
            }
            : {}),
    };
}
function getTeamInfoNameStyle() {
    return {
        width: '80%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
}
function getRequestStatusStyle(ownerState, theme) {
    const defaultStyles = {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        fontWeight: theme.typography.tsFontWeight.bold,
        padding: '10px 0 10px 0',
        textAlign: 'right',
    };
    if (ownerState['ts-variant']?.toLowerCase() === 'approved') {
        return {
            ...defaultStyles,
            color: `${globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? theme.palette.hsbcColor.green : theme.palette.tsGoColor.green} !important`,
        };
    }
    else if (ownerState['ts-variant']?.toLowerCase() === 'pending') {
        return {
            ...defaultStyles,
            color: `${theme.palette.tsGoColor.yellow} !important`,
        };
    }
    else if (ownerState['ts-variant']?.toLowerCase() === 'declined') {
        return {
            ...defaultStyles,
            color: `${theme.palette.tsGoColor.red} !important`,
        };
    }
    else {
        return {
            ...defaultStyles,
        };
    }
}
function getIntroHeader(theme) {
    const defaultStyles = {
        color: 'hsl(199, 20%, 22%)',
        fontWeight: '700',
        textAlign: 'center',
    };
    return {
        fontSize: '48px',
        lineHeight: '60px',
        maxWidth: '600px',
        margin: '20px auto 40px',
        ...defaultStyles,
        [theme.breakpoints.down(415)]: {
            fontSize: '24px',
            lineHeight: '30px',
            maxWidth: '80%',
            margin: '20px 10px 40px',
            ...defaultStyles,
        },
    };
}
function getPitchTextStyle() {
    return { fontSize: '18px', marginBottom: '40px' };
}
function getLandingSubHeaderStyle(theme) {
    return {
        color: theme.palette.tsGoColor.slateLighter,
        fontWeight: theme.typography.tsFontWeight.lighter,
        marginBottom: '20px',
        textAlign: 'center',
        width: 'auto',
        [theme.breakpoints.down(415)]: {
            width: '80%',
        },
    };
}
function getHowItWorksInfoStyle(theme) {
    return {
        display: 'inline-block',
        fontWeight: theme.typography.tsFontWeight.lighter,
        margin: '0 auto',
        maxWidth: '370px',
        paddingBottom: '20px',
        [theme.breakpoints.down(415)]: {
            margin: 0,
            width: '80%',
        },
    };
}
function getHowItWorksStepsStyle(theme) {
    return {
        color: theme.palette.tsGoColor.grayDark,
        fontSize: theme.typography.tsFontSize.default,
        fontWeight: theme.typography.tsFontWeight.default,
    };
}
function getTakeControlTextStyle(theme) {
    return {
        margin: '0 auto 40px',
        maxWidth: '580px',
        textAlign: 'center',
        color: theme.palette.tsGoColor.slateLighter,
        fontWeight: theme.typography.tsFontWeight.lighter,
    };
}
function getEmptyWalletTextStyle() {
    return {
        color: 'hsl(198, 20%, 59%)',
        fontSize: '16px',
        textAlign: 'center',
    };
}
exports.muiTypographyOverrides = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            variants: [
                {
                    props: {
                        variant: 'subtitle1',
                    },
                    style: getSubtitle1Style(theme),
                },
                {
                    props: {
                        variant: 'subtitle2',
                    },
                    style: getSubtitle2Style(theme),
                },
                {
                    props: {
                        variant: 'h6',
                    },
                    style: getH6Style(theme),
                },
                {
                    props: {
                        variant: 'body1',
                    },
                    style: getBody1Style(theme),
                },
                {
                    props: {
                        variant: 'body2',
                    },
                    style: getBody2Style(theme),
                },
                {
                    props: {
                        'ts-variant': 'active',
                        variant: 'body2',
                    },
                    style: getActiveButtonStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'inactive',
                        variant: 'body2',
                    },
                    style: getInactiveButtonStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'tableCellTitle',
                        variant: 'subtitle2',
                    },
                    style: getTableCellTitleStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'tableCellTitleName',
                        variant: 'subtitle2',
                    },
                    style: getTableCellTitleNameStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'tableCellSubtitle',
                        variant: 'subtitle1',
                    },
                    style: getTableCellSubtitleStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'tableCellSubtitleSC',
                        variant: 'subtitle1',
                    },
                    style: getTableCellSubtitleStyleSC(theme),
                },
                {
                    props: {
                        'ts-variant': 'tableHeaderTitle',
                        variant: 'h5',
                    },
                    style: getTableHeaderCellTitleStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'no-list-info',
                        variant: 'h5',
                    },
                    style: getNoListStyle(),
                },
                {
                    props: {
                        'ts-variant': 'imgSpan',
                    },
                    style: getImgSpanStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'imgSideSpan',
                    },
                    style: getImgSideSpanStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'imgSub',
                    },
                    style: getImgSubStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'navigation-item',
                    },
                    style: getNavigationDefaultStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'navigation-item active underline',
                    },
                    style: getNavigationActiveStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'title',
                    },
                    style: getTitleStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'title-info',
                    },
                    style: getTitleInfoStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'imgSideSub',
                    },
                    style: getImgSideSubStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'h3',
                    },
                    style: getH3Style(theme),
                },
                {
                    props: {
                        'ts-variant': 'userAvatar',
                    },
                    style: getUserAvatarStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'sideMenuSpan',
                    },
                    style: getSidemenuSpan(theme),
                },
                {
                    props: {
                        'ts-variant': 'sideMenuItemSpan',
                    },
                    style: getSidemenuItemSpan(theme),
                },
                {
                    props: {
                        'ts-variant': 'myRequestSpan',
                    },
                    style: getMyRequestItemSpan(theme),
                },
                {
                    props: {
                        'ts-variant': 'myRequestSecondaySpan',
                    },
                    style: getMyRequestItemSecondarySpan(theme),
                },
                {
                    props: {
                        'ts-variant': 'span',
                    },
                    style: getSpanStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'header',
                    },
                    style: getHeaderStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'header no-text-trunc',
                    },
                    style: getHeaderStyleNoTextTrunc(theme),
                },
                {
                    props: {
                        'ts-variant': 'sideMenmuFooterDetailDiv',
                    },
                    style: getSideMenuFooterDetailStyle(theme),
                },
                {
                    props: ({ ownerState }) => ownerState['ts-variant'] === 'sidebarHeaderIconDiv' ||
                        ownerState['ts-variant'] === 'sidebarHeaderIconCollapsedDiv',
                    style: getSideMenuHeaderIconStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'teamSwitcherHeaderTitle',
                    },
                    style: getTeamSwitcherHeaderTitle(theme),
                },
                {
                    props: {
                        'ts-variant': 'teamName',
                    },
                    style: getTeamName(theme),
                },
                {
                    props: {
                        'ts-variant': 'roleName',
                    },
                    style: getRoleName(theme),
                },
                {
                    props: {
                        'ts-variant': 'multiLine',
                    },
                    style: getMultiLineStyle(),
                },
                {
                    props: {
                        'ts-variant': 'secondary',
                    },
                    style: getSecondaryDivStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'select-item',
                    },
                    style: getSelectItemStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'roleNameSwitcher',
                    },
                    style: getRoleNameStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'intro-header',
                    },
                    style: getIntroHeader(theme),
                },
                {
                    props: {
                        'ts-variant': 'pitch-text',
                    },
                    style: getPitchTextStyle(),
                },
                {
                    props: {
                        'ts-variant': 'how-it-works-text',
                    },
                    style: getLandingSubHeaderStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'how-it-works-info',
                    },
                    style: getHowItWorksInfoStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'how-it-works-steps',
                    },
                    style: getHowItWorksStepsStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'take-control-text',
                    },
                    style: getTakeControlTextStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'speed-up-accounting-text',
                    },
                    style: getLandingSubHeaderStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'empty-state',
                    },
                    style: getEmptyWalletTextStyle(),
                },
                {
                    props: {
                        'ts-variant': 'teamInfoNameStyle',
                        variant: 'body1',
                    },
                    style: getTeamInfoNameStyle(),
                },
                {
                    props: ({ ownerState }) => ownerState['ts-request-status'] === 'requestStatus' && ownerState['ts-variant'],
                    style: getRequestStatusStyle(ownerState, theme),
                },
            ],
        }),
    },
};
