// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
const SvgStatusConfirmationOnLight = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<path
			d="M0 0h18v18H0z"
			data-name="Bounding box"
			style={{
				fill: 'none',
			}}
		/>
		<circle
			cx={9}
			cy={9}
			r={9}
			style={{
				fill: '#00847f',
			}}
		/>
		<path
			d="M7.22 13.55 3.59 9.92l1.27-1.27 2.36 2.36 5.92-5.93 1.27 1.28z"
			style={{
				fill: '#fff',
			}}
		/>
	</svg>
);
export default SvgStatusConfirmationOnLight;
