"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_query_1 = require("@tanstack/react-query");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const appConstants_1 = require("../../../../constants/appConstants");
const StyledBox_1 = require("../../../../material-ui-theme/components/styled/StyledBox");
const teamQuery_1 = require("../../../../queries/teamQuery");
const userSelectors_1 = require("../../../../selectors/userSelectors");
const zustandStore_1 = require("../../../../zustandStore");
const walletHelper_1 = require("../../wallet/walletHelper");
const ActivityHeaderActions = () => {
    const initialData = (0, react_router_dom_1.useLoaderData)();
    const { teamId, filters, options, setActivityFilters, setActivityOptions } = (0, zustandStore_1.useZustand)(state => ({
        teamId: state.team.team?.id,
        filters: state.activity.filters,
        options: state.activity.options,
        setActivityFilters: state.setActivityFilters,
        setActivityOptions: state.setActivityOptions,
    }));
    const isUserCompanyAdminOrSupport = initialData && 'user' in initialData && (0, userSelectors_1.isCompanyOrSupportAdmin)(initialData.user?.roles || []);
    const { data: filterTeam } = (0, react_query_1.useQuery)((0, teamQuery_1.getTeamUsersQuery)({
        teamId: isUserCompanyAdminOrSupport ? (filters.team === 'all' ? null : filters.team) : teamId,
    }));
    (0, react_1.useEffect)(() => {
        function fetchData() {
            const memberData = (0, walletHelper_1.buildTeamMemberOptions)({
                allMembersFromAllTeams: initialData && 'allMembersFromAllTeams' in initialData && initialData.allMembersFromAllTeams,
                filterTeam,
                isUserCompanyAdminOrSupport,
            });
            setActivityOptions({
                ...options,
                teamOptions: initialData && 'teams' in initialData ? initialData.teams : [],
                memberOptions: memberData,
            });
        }
        fetchData();
    }, [initialData, filterTeam, isUserCompanyAdminOrSupport]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(material_1.Grid2, { container: true, sx: {
                marginBottom: 1,
                paddingRight: '10px',
            }, children: (0, jsx_runtime_1.jsx)(material_1.Grid2, { sx: { fontWeight: 600 }, size: 8, children: (0, jsx_runtime_1.jsx)(StyledBox_1.StyledActivityHeaderBox, { "data-testid": "activity-header-actions", children: (0, jsx_runtime_1.jsxs)(material_1.Box, { "data-testid": "activity-filters", sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 1,
                        }, children: [appConstants_1.defaultAllowedRoles.includes(initialData && 'userRole' in initialData ? initialData.userRole : '') ? ((0, jsx_runtime_1.jsx)(material_1.Select, { "data-testid": "filter-teams", value: filters.team, onChange: event => {
                                    setActivityFilters({ ...filters, team: event.target.value, member: 'all' });
                                }, sx: { minWidth: 150 }, required: true, children: options?.teamOptions?.map(type => ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: type.id, children: type.label }, type.id))) }, "filterTeams")) : null, [...appConstants_1.defaultAllowedRoles, appConstants_1.teamAdmin].includes(initialData && 'userRole' in initialData ? initialData.userRole : '') ? ((0, jsx_runtime_1.jsx)(material_1.Select, { "data-testid": "filter-members", value: filters.member, onChange: event => {
                                    setActivityFilters({ ...filters, member: event.target.value });
                                }, sx: { minWidth: 150 }, required: true, children: options?.memberOptions?.map(type => ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: type.id, children: type.label }, type.id))) }, "filterMembers")) : null] }) }) }) }) }));
};
exports.default = ActivityHeaderActions;
