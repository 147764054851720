"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const TypeInput = ({ name = '', id = '', value = '', placeholder = '', onValueChange = () => ({}), disabled = false, readOnly = false, required = false, pattern = '.', type = 'text', endAdornment = null, dataTestId = '', maxLength = 999, }) => {
    const uniqId = (0, react_1.useId)();
    const onInputChange = event => {
        if (event.target) {
            const { value: newValue } = event.target;
            onValueChange(name, newValue);
        }
    };
    return ((0, jsx_runtime_1.jsx)(material_1.OutlinedInput, { inputProps: {
            pattern,
            'data-testid': dataTestId,
            maxlength: maxLength,
        }, endAdornment: endAdornment, id: id || uniqId, readOnly: readOnly, name: name, required: required, disabled: disabled, type: type, value: value, placeholder: placeholder, onChange: onInputChange, "data-testid": `${dataTestId}-wrapper` }));
};
exports.default = TypeInput;
